<template>
  <b-container>
    <h1>Indicadores</h1>
  </b-container>
</template>

<script>
export default {
  name: "Indicadores",
};
</script>

<style></style>
